/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { InfoCircle } from '@grupo-sbf/motriz-icons/centauro';
import { centauro } from '@grupo-sbf/motriz-tokens/themes';
import Popover from '@mui/material/Popover';
import { useState } from 'react';

export default function HelperPopover(props) {
  const [anchorEl, setAnchorEl] = useState();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl();
  };

  const { children } = props;

  const styleIcon = {
    width: '24px',
    height: '24px',
    color: centauro.color.neutral[600],
  };

  return (
    <span>
      <InfoCircle
        style={styleIcon}
        onMouseEnter={(evt) => handlePopoverOpen(evt)}
        onMouseLeave={(evt) => handlePopoverClose(evt)}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        PaperProps={{ sx: { maxWidth: '450px' } }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </span>
  );
}
